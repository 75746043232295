
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

.emeral-logo{
  height: 30px;
  width: 171.5px;
}

.emeral-red{
  background-color: $color-accion;
  border-color: darken($color-accion, 5%);
  &:hover{
    background-color: darken($color-accion, 5%);
    border-color: darken($color-accion, 10%);
  }
}

.partner-md img {
  height: 30px;
}

.footer-logo{
  margin-top: 10px;
  path{
    opacity: 0;
  }
}

.bg__home{
  background: url(/assets-static/img/site/i-home-900.png) right bottom/auto 100% no-repeat;
}

.bg__home__onboarding{
  background: url(/assets-static/img/site/onboarding-hero.png) right bottom/auto 100% no-repeat;
}

@media only screen and (max-width: 1199px) {
  .bg__home{
    background: url(/assets-static/img/site/i-home-900.png) right center/auto 80% no-repeat;
  }
}

@media only screen and (max-width: 1199px) {
  .bg__home__onboarding{
    background: url(/assets-static/img/site/onboarding-hero.png) right bottom/auto 100% no-repeat;
  }
}

@media only screen and (max-width: 991px) {
  .bg__home{
    background: none;
  }
}

@media only screen and (max-width: 991px) {
  .bg__home__onboarding{
    background: none;
  }
}

@media only screen and (max-width: 767px) {
  background: none;
}

@media only screen and (max-width: 767px) {
  h1{
    font-size: 2.2em;
  }
}
